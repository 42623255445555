<template>
  <div>
    <v-sheet v-if="loading" class="mb-4 px-6 py-6 outlined" elevation="1">
      <Spinner class="mx-auto" />
    </v-sheet>
    <div
      v-else-if="!insuranceTotals.length || error"
      class="pa-4 rounded aon-gray-07"
    >
      <span>{{ $t('vendors.noInsuranceRequirements') }}</span>
    </div>
    <div v-else>
      <VendorContracts
        :key="`contracts-${vendor.id}`"
        :vendor="vendor"
        :notification-params="notificationParams"
        :insurance-totals="insuranceTotals"
        @initialize="getInsurance"
      />
    </div>
  </div>
</template>

<script>
import VendorContracts from './VendorContracts';
import {
  getVendorInsuranceTotals,
  getVendorInsuranceTotalsMigrated,
} from '@/api/insurance';
import { mapState } from 'vuex';

export default {
  name: 'VendorData',
  components: {
    VendorContracts,
  },
  props: {
    vendor: {
      type: Object,
      required: true,
    },
    notificationParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      insuranceTotals: [],
    };
  },
  computed: {
    ...mapState('companies', {
      companyIsMigrated: state => state?.company?.migrated,
    }),
  },
  async created() {
    await this.getInsurance();
  },
  methods: {
    async getInsurance() {
      this.insuranceTotals = [];
      this.loading = true;
      this.error = false;

      try {
        const { data } = this.companyIsMigrated
          ? await getVendorInsuranceTotalsMigrated(this.vendor.vendorId)
          : await getVendorInsuranceTotals(this.vendor.vendorId);
        this.insuranceTotals = data
          .map(d => ({
            ...d,
            fileDownloadsLoading: false,
          }))
          .sort((a, b) => {
            if (a.compliant === b.compliant) {
              return this.$t(
                `mappings.insurance.${a.insuranceType}`
              ).localeCompare(this.$t(`mappings.insurance.${b.insuranceType}`));
            } else if (!a.compliant) {
              return -1;
            } else {
              return 1;
            }
          });
      } catch {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
