<template>
  <v-dialog v-model="open" max-width="40em">
    <template v-slot:activator="{ on }">
      <AppButton
        variation="ghost"
        prepend-icon="fal fa-external-link-square"
        v-on="on"
      >
        {{ $t('vendors.showRequirements') }}
      </AppButton>
    </template>
    <Spinner v-if="loading" />
    <Alert
      v-else-if="error"
      full-width
      persistent
      :title="$t('policy.error')"
      type="error"
    />
    <v-sheet v-else class="px-8 py-8 agreement-requirement-modal">
      <div class="agreement-list-item d-flex justify-space-between">
        <h5 class="pt-2">{{ clientName }}</h5>
      </div>
      <!-- Effective dates section -->
      <div class="agreement-list-item">
        <h2>
          {{ $t('vendors.effectiveDate') }}
        </h2>
        <div class="list-details d-inline-flex justify-space-between">
          <div class="agreement-modal-column">
            <div class="placeholder"></div>
            <div>
              <i :class="complianceClass(effectiveInsurance)" />
              {{ $t('vendors.eff') }}
            </div>
            <div>
              <i :class="complianceClass(expiredInsurance)" />
              {{ $t('vendors.exp') }}
            </div>
          </div>
          <div class="agreement-modal-column">
            <div>{{ $t('vendors.required') }}</div>
            <div>{{ contract.commence | longDate }}</div>
            <div>{{ contractCease }}</div>
          </div>
          <div class="agreement-modal-column">
            <div>{{ $t('vendors.demonstrated') }}</div>
            <div>{{ insuranceCommence }}</div>
            <div>{{ insuranceCease }}</div>
          </div>
        </div>
      </div>
      <!-- Limits and deductibles section -->
      <div
        v-if="!companyIsMigrated && showLimitsAndDeductiblesSection"
        class="agreement-list-item"
      >
        <h2>
          {{ $t('vendors.limitsAndDeductibles') }}
        </h2>
        <div class="list-details d-inline-flex justify-space-between">
          <div class="agreement-modal-column">
            <div class="placeholder"></div>
            <div
              v-if="
                showRequirement(
                  insuranceTotal.insuranceType,
                  insuranceTotal.aggregateRequirement
                )
              "
            >
              <i :class="complianceClass(aggregateCompliance)" />
              {{ $t('vendors.aggregateCoverage') }}
            </div>
            <div
              v-if="
                showRequirement(
                  insuranceTotal.insuranceType,
                  insuranceTotal.perOccurrenceRequirement
                )
              "
            >
              <i :class="complianceClass(perOccurrenceCompliance)" />
              {{ showPerOccurrenceLabel(insuranceTotal.insuranceType) }}
            </div>
            <div
              v-if="
                showRequirement(
                  insuranceTotal.insuranceType,
                  insuranceTotal.deductibleRequirement,
                  'deductible'
                )
              "
            >
              <i :class="complianceClass(deductibleCompliance)" />
              {{ $t('vendors.deductible') }}
            </div>
          </div>
          <div class="agreement-modal-column-primary">
            <div>{{ $t('vendors.required') }}</div>
            <div
              v-if="
                showRequirement(
                  insuranceTotal.insuranceType,
                  insuranceTotal.aggregateRequirement
                )
              "
            >
              {{
                formatRequirement(
                  insuranceTotal.insuranceType,
                  insuranceTotal.aggregateRequirement
                )
              }}
            </div>
            <div
              v-if="
                showRequirement(
                  insuranceTotal.insuranceType,
                  insuranceTotal.perOccurrenceRequirement
                )
              "
            >
              {{
                formatRequirement(
                  insuranceTotal.insuranceType,
                  insuranceTotal.perOccurrenceRequirement
                )
              }}
            </div>
            <div
              v-if="
                showRequirement(
                  insuranceTotal.insuranceType,
                  insuranceTotal.deductibleRequirement,
                  'deductible'
                )
              "
            >
              {{
                formatRequirement(
                  insuranceTotal.insuranceType,
                  insuranceTotal.deductibleRequirement
                )
              }}
            </div>
          </div>
          <div class="agreement-modal-column-secundary">
            <div>{{ $t('vendors.demonstrated') }}</div>
            <v-tooltip
              v-if="insuranceTotal.hasUnverifiedCoi"
              bottom
              open-on-hover
            >
              <template v-slot:activator="{ on }">
                <div class="unverified-limits" v-on="on">
                  {{
                    formatRequirement(
                      insuranceTotal.insuranceType,
                      insuranceTotal.unverifiedAggregateCoverage
                    )
                  }}
                </div>
              </template>
              <span>{{ $t('vendors.insurance.pendingCoiVerification') }}</span>
            </v-tooltip>
            <div v-else>
              {{
                showRequirement(
                  insuranceTotal.insuranceType,
                  insuranceTotal.aggregateRequirement
                )
                  ? formatRequirement(
                      insuranceTotal.insuranceType,
                      insuranceTotal.similarAggregateCoverage
                    )
                  : ''
              }}
            </div>
            <v-tooltip
              v-if="insuranceTotal.hasUnverifiedCoi"
              bottom
              open-on-hover
            >
              <template v-slot:activator="{ on }">
                <div class="unverified-limits" v-on="on">
                  {{
                    formatRequirement(
                      insuranceTotal.insuranceType,
                      insuranceTotal.unverifiedPerOccurrenceCoverage
                    )
                  }}
                </div>
              </template>
              <span>{{ $t('vendors.insurance.pendingCoiVerification') }}</span>
            </v-tooltip>
            <div v-else>
              {{
                showRequirement(
                  insuranceTotal.insuranceType,
                  insuranceTotal.perOccurrenceRequirement
                )
                  ? formatRequirement(
                      insuranceTotal.insuranceType,
                      insuranceTotal.similarPerOccurrenceCoverage
                    )
                  : ''
              }}
            </div>
            <v-tooltip
              v-if="insuranceTotal.hasUnverifiedCoi"
              bottom
              open-on-hover
            >
              <template v-slot:activator="{ on }">
                <div class="unverified-limits" v-on="on">
                  {{
                    formatRequirement(
                      insuranceTotal.insuranceType,
                      insuranceTotal.unverifiedDeductibleCoverage
                    )
                  }}
                </div>
              </template>
              <span>{{ $t('vendors.insurance.pendingCoiVerification') }}</span>
            </v-tooltip>
            <div v-else>
              {{
                showRequirement(
                  insuranceTotal.insuranceType,
                  insuranceTotal.deductibleRequirement,
                  'deductible'
                )
                  ? formatRequirement(
                      insuranceTotal.insuranceType,
                      insuranceTotal.similarDeductibleCoverage
                    )
                  : ''
              }}
            </div>
          </div>
          <div
            v-if="insuranceTotal.requirementAdjustment"
            class="agreement-modal-column-secundary"
          >
            <div>{{ $t('vendors.insurance.coverageGap') }}</div>
            <div>
              {{
                showRequirement(
                  insuranceTotal.insuranceType,
                  insuranceTotal.requirementAdjustment.adjustmentAmount
                )
                  ? formatRequirement(
                      insuranceTotal.insuranceType,
                      insuranceTotal.requirementAdjustment.adjustmentAmount
                    )
                  : ''
              }}
            </div>
            <div>
              {{
                showRequirement(
                  insuranceTotal.insuranceType,
                  insuranceTotal.requirementAdjustment
                    .perOccurrenceAdjustmentAmount
                )
                  ? formatRequirement(
                      insuranceTotal.insuranceType,
                      insuranceTotal.requirementAdjustment
                        .perOccurrenceAdjustmentAmount
                    )
                  : ''
              }}
            </div>
            <div>
              {{
                showRequirement(
                  insuranceTotal.insuranceType,
                  insuranceTotal.requirementAdjustment
                    .deductibleAdjustmentAmount
                )
                  ? formatRequirement(
                      insuranceTotal.insuranceType,
                      insuranceTotal.requirementAdjustment
                        .deductibleAdjustmentAmount
                    )
                  : ''
              }}
            </div>
          </div>
        </div>
      </div>
      <!-- Sub limits section -->
      <div
        v-if="!companyIsMigrated && contractSublimits.length"
        class="agreement-list-item"
      >
        <h2>{{ $t('vendors.sublimits') }}</h2>
        <div class="list-details">
          <div
            v-for="(s, i) in contractSublimits"
            :key="`subreq_${i}`"
            class="list-sublimits"
          >
            <i :class="complianceClass(subreqCompliance(s))" />
            <SubLimitsListItemLabel
              :insurance-type="insuranceTotal.insuranceType"
              :type="formatSubLimitsItemType(s)"
              :title="
                $t(
                  `vendors.SUB_LIMIT.${insuranceTotal.insuranceType}.${s.name}`
                )
              "
              :value="s.value"
            />
          </div>
        </div>
      </div>
      <!-- Additional coverage section -->
      <div
        v-if="!companyIsMigrated && contractAdditionalCoverages.length"
        class="agreement-list-item"
      >
        <h2>
          {{ $t('vendors.additionalCoverage') }}
        </h2>
        <div class="list-details">
          <div
            v-for="(s, i) in contractAdditionalCoverages"
            :key="`subreq_${i}`"
          >
            <i :class="complianceClass(subreqCompliance(s))" />
            {{
              s.name === 'AUTO_LIAB'
                ? $t(`vendors.ADDITIONAL_COVERAGE.AUTO_LIAB.${s.value}`)
                : $t(`vendors.ADDITIONAL_COVERAGE.OTHER.${s.name}`)
            }}
          </div>
        </div>
      </div>
      <!-- Named endorsements section -->
      <div
        v-if="!companyIsMigrated && contractEndorsements.length"
        class="agreement-list-item"
      >
        <h2>
          {{ $t('vendors.namedEndorsements') }}
        </h2>
        <div class="list-details">
          <div v-for="(s, i) in contractEndorsements" :key="`subreq_${i}`">
            <i :class="complianceClass(subreqCompliance(s))" />
            {{ $t(`vendors.ENDORSEMENT.${s.name}`) }}
          </div>
        </div>
      </div>
      <!-- COI verification section -->
      <div
        v-if="!companyIsMigrated && contractCoiClient.length"
        class="agreement-list-item agreement-no-border"
      >
        <h2>
          {{ $t('vendors.coiVerification') }}
        </h2>
        <div class="list-details">
          <div v-for="(s, i) in contractCoiClient" :key="`subreq_${i}`">
            <i :class="complianceClass(subreqCompliance(s))" />
            {{ $t(`vendors.COI_CLIENT.${s.name}`) }}
          </div>
        </div>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import { getPolicies } from '@/api/insurance';
import SubLimitsListItemLabel from '@/components/SubLimitsListItemLabel';
import {
  coiClient,
  formatRequirement,
  endorsement,
  additionalCoverage,
  sublimit,
} from '@/lib/helpers';

export default {
  name: 'AgreementRequirementModal',
  components: { SubLimitsListItemLabel },
  props: {
    contract: {
      type: Object,
      required: true,
    },
    insuranceTotal: {
      type: Object,
      default: null,
    },
    vendorId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      open: false,
      loading: false,
      policy: null,
    };
  },
  computed: {
    ...mapGetters('insurances', ['getInsurancePoliciesByType']),
    ...mapState('clients', {
      clientName: state => state.clientName,
    }),
    ...mapState('companies', {
      companyIsMigrated: state => state?.company?.migrated,
    }),
    contractCease() {
      return this.contract.cease
        ? this.$options.filters.longDate(this.contract.cease)
        : this.$t('vendors.contractsHeaders.evergreen');
    },
    insuranceCommence() {
      return this.policy
        ? this.$options.filters.longDate(this.policy.effectiveDate)
        : '--';
    },
    insuranceCease() {
      return this.policy
        ? this.$options.filters.longDate(this.policy.expirationDate)
        : '--';
    },
    effectiveInsurance() {
      if (!this.policy?.effectiveDate || !this.contract?.commence) {
        return false;
      }
      const requiredDate = new Date(this.contract.commence);
      const demonstratedDate = new Date(this.policy.effectiveDate);
      const todayDate = new Date();

      if (demonstratedDate > requiredDate) {
        if (todayDate < demonstratedDate) {
          return false;
        }
      }
      return true;
    },
    expiredInsurance() {
      return (
        !!this.policy &&
        new Date(this.contract.cease) <= new Date(this.policy.expirationDate)
      );
    },
    aggregateCompliance() {
      const gapAmount = parseInt(this.insuranceTotal?.aggregateAdjustment) || 0;
      const similarAggregateCoverage = parseInt(
        this.insuranceTotal?.similarAggregateCoverage || 0
      );
      return (
        similarAggregateCoverage + gapAmount >=
        this.insuranceTotal.aggregateRequirement
      );
    },
    perOccurrenceCompliance() {
      const gapAmount =
        parseInt(this.insuranceTotal?.perOccurrenceAdjustment) || 0;
      const similarPerOccurrenceCoverage = parseInt(
        this.insuranceTotal?.similarPerOccurrenceCoverage || 0
      );
      return (
        similarPerOccurrenceCoverage + gapAmount >=
        this.insuranceTotal.perOccurrenceRequirement
      );
    },
    deductibleCompliance() {
      // True when waiving the full requirement instead adjustmentReason:"CLIENT_RISK"
      if (
        this.insuranceTotal.requirementAdjustment !== null &&
        this.insuranceTotal.requirementAdjustment.adjustmentReason === 'EXEMPT'
      ) {
        return true;
      }
      const gapAmount =
        parseInt(this.insuranceTotal?.deductibleAdjustment) || 0;
      const similarDeductibleCoverage = parseInt(
        this.insuranceTotal?.similarDeductibleCoverage || 0
      );
      return (
        (this.insuranceTotal?.similarDeductibleCoverage !== null ||
          (this.insuranceTotal?.requirementAdjustment !== null &&
            this.insuranceTotal?.requirementAdjustment
              ?.deductibleAdjustmentAmount !== null)) &&
        similarDeductibleCoverage + gapAmount <=
          this.insuranceTotal.deductibleRequirement
      );
    },
    contractSubreqs() {
      const subreqs = this.contract.insuranceRequirements
        .filter(r => r.insuranceType === this.insuranceTotal.insuranceType)
        .flatMap(r => r.subrequirements);
      return subreqs.filter((s, i) => subreqs.indexOf(s === i));
    },
    contractSublimits() {
      return this.sublimit(this.contractSubreqs);
    },
    contractEndorsements() {
      return this.endorsement(this.contractSubreqs);
    },
    contractCoiClient() {
      return this.coiClient(this.contractSubreqs);
    },
    contractAdditionalCoverages() {
      return this.additionalCoverage(this.contractSubreqs).flatMap(
        a => a.values
      );
    },
    showLimitsAndDeductiblesSection() {
      return !(
        (this.insuranceTotal.aggregateRequirement === 0 ||
          this.insuranceTotal.aggregateRequirement === null) &&
        (this.insuranceTotal.perOccurrenceRequirement === 0 ||
          this.insuranceTotal.perOccurrenceRequirement === null) &&
        (this.insuranceTotal.deductibleRequirement === 0 ||
          this.insuranceTotal.deductibleRequirement === null)
      );
    },
  },
  async created() {
    this.loading = true;
    this.error = false;
    try {
      const { data } = await getPolicies({
        vendorId: this.vendorId,
        type: this.insuranceTotal.insuranceType,
      });
      const policies = data.filter(p => moment(p.expirationDate).isAfter());
      this.policy = policies.length ? policies[0] : null;
    } catch (error) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    coiClient,
    formatRequirement,
    endorsement,
    additionalCoverage,
    sublimit,
    showPerOccurrenceLabel(insuranceType) {
      switch (insuranceType) {
        case 'LIQUOR_LIAB':
          return this.$t('vendors.eachCommonCause');
        case 'EO_PROF_LIAB':
          return this.$t('vendors.eachClaim');
        case 'BUSINESS':
          return this.$t('vendors.periodOfIndemnity');
        case 'COM_AUTO':
          return this.$t('vendors.combinedSingleLimit');
        default:
          return this.$t('vendors.perOccurrenceCoverage');
      }
    },
    complianceClass(compliant) {
      return compliant
        ? 'fas fa-check compliance-status compliant'
        : 'fas fa-exclamation compliance-status';
    },
    formatSubLimitsItemType(s) {
      return s?.meta?.typeValue || '';
    },
    subreqCompliance(subreq) {
      // True when waiving the full requirement instead adjustmentReason:"CLIENT_RISK"
      if (
        this.insuranceTotal.requirementAdjustment !== null &&
        this.insuranceTotal.requirementAdjustment.adjustmentReason === 'EXEMPT'
      ) {
        return true;
      }
      if (
        subreq &&
        subreq.meta &&
        subreq.meta.typeValue &&
        subreq.meta.typeValue === 'AMOUNT'
      ) {
        // Get policy value from store insurances.vendorInsuranceTotal.policySubrequirements and insurances.insurancePolicies
        const policySubrequirement = this.insuranceTotal.policySubrequirements.find(
          el => el.name === subreq.name
        );
        const policySubrequirementValue = parseInt(
          (policySubrequirement && policySubrequirement.value) || 0
        );

        // Get coverage gap from this.insuranceTotal.subrequirement.meta?.adjustmentValue
        const subrequirement = this.insuranceTotal.subrequirements.find(
          el => el.name === subreq.name
        );
        const gapSubrequirementValue = parseInt(
          subrequirement?.meta?.adjustmentValue || 0
        );

        return (
          parseInt(subreq.value) <=
          policySubrequirementValue + gapSubrequirementValue
        );
      }
      // For boolean subrequirements as SUB_LIMIT, ADITIONAL_COVERAGE, and ENDORSEMENT, Get coverage gap from this.insuranceTotal?.requirementAdjustment?.subrequirements
      if (this.insuranceTotal?.requirementAdjustment?.subrequirements) {
        let inresub = this.insuranceTotal?.requirementAdjustment?.subrequirements.find(
          el => el.name === subreq.name && el.type === subreq.type
        );
        if (
          subreq.name === 'AUTO_LIAB' &&
          subreq.type === 'ADDITIONAL_COVERAGE'
        ) {
          inresub = this.insuranceTotal?.requirementAdjustment?.subrequirements.find(
            el =>
              el.name === subreq.name &&
              el.type === subreq.type &&
              el.value === subreq.value
          );
        }
        if (!inresub) {
          // if not found then true because it is waivered now
          return true;
        } else {
          return (
            this.insuranceTotal.policySubrequirements.findIndex(
              s => s.name === inresub.name && s.type === inresub.type
            ) !== -1
          );
        }
      }

      // Is ADDITIONAL_COVERAGE? validate name, type and value
      if (
        subreq.name === 'AUTO_LIAB' &&
        subreq.type === 'ADDITIONAL_COVERAGE'
      ) {
        return (
          this.insuranceTotal.policySubrequirements.findIndex(
            s =>
              s.name === subreq.name &&
              s.type === subreq.type &&
              (s.value === subreq.value || s.value === 'ANY')
          ) !== -1
        );
      }
      return (
        this.insuranceTotal.policySubrequirements.findIndex(
          s => s.name === subreq.name && s.type === subreq.type
        ) !== -1
      );
    },
    showRequirement(insuranceType, value, field = '') {
      // Hide this Aggregate/Per Occurence/Deductible field when value is 0 or null
      if (value === 0 || value === null) {
        return false;
      }
      if (insuranceType === 'LIQUOR_LIAB' && field === 'deductible') {
        return false;
      }
      if (insuranceType === 'WRK_COMP' && (value === 0 || value === null)) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.agreement-requirement-modal {
  overflow-x: hidden;

  .agreement-list-item {
    border-bottom: 1px solid gray;
    margin-bottom: 8px;
    padding-bottom: 8px;

    h2 {
      margin-bottom: 8px;
      font-size: 24px;
    }

    .list-details {
      margin-left: 24px;

      .list-sublimits {
        display: flex;
      }
    }

    .compliance-status {
      padding: 4px;
      border-radius: 3px;
      margin-bottom: 4px;
      color: orange;

      &.compliant {
        color: green;
      }
    }
  }

  .agreement-no-border {
    border-bottom: 0 !important;
  }

  .agreement-modal-column {
    width: 210px;

    .unverified-limits {
      color: #ffa600;
    }

    .placeholder {
      min-height: 24px;
    }
  }
  .agreement-modal-column-primary {
    width: 100px;
  }
  .agreement-modal-column-secundary {
    width: 130px;
  }
}
</style>
