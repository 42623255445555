<template>
  <div :key="contract.id" class="insurance-agreement">
    <v-card
      outlined
      flat
      class="mb-2"
      @click="e => (showRequirements = !showRequirements)"
    >
      <v-card-text>
        <div class="d-flex align-center justify-space-between mb-4">
          <span class="contract-title aon-gray-01--text">
            <i v-if="isActive" :class="agreementStatus" class="mr-2" />
            {{ contract.name }}
          </span>
          <div class="d-flex align-center">
            <Status :color="statusColor" class="mx-4">
              {{ status }}
            </Status>
            <VendorContractActions
              v-if="
                (contract.status === acceptedStatus &&
                  !contract.pendingStatus) ||
                  isActive
              "
              :contract="contract"
              @updateContract="e => $emit('updateContract')"
            />
          </div>
        </div>
        <div>
          <LabelValueRow :items="labelValues" />
          <ContractRequest
            v-if="!!contract.pendingStatus"
            class="full-width mt-4"
            :vendor="vendor"
            :contract="contract"
            :notification-params="notificationParams"
            @updateContract="e => $emit('updateContract')"
          />
        </div>
      </v-card-text>
    </v-card>
    <div v-if="showRequirements && !inactive">
      <div class="two-column">
        <div>
          <div v-for="(ins, i) in leftList" :key="`total_left_${i}`">
            <InsuranceAgreementTotal
              :contract="contract"
              :insurance-total="ins"
              :pending-adjustment-request="pendingAdjustmentRequest(ins)"
              :subreqs-missing="subreqsMissing(ins)"
              :limits-missing="limitsMissing(ins)"
              :expired-coverage="expiredCoverage(ins)"
              :unverified-policy="unverifiedPolicy(ins)"
              @adjustmentRequestSubmit="e => $emit('initialize')"
              @withdrawAdjustmentRequest="
                e => $emit('withdrawAdjustmentRequest', e)
              "
              @updateContract="e => $emit('initialize')"
            />
          </div>
        </div>
        <div>
          <div v-for="(ins, i) in rightList" :key="`total_right_${i}`">
            <InsuranceAgreementTotal
              :contract="contract"
              :insurance-total="ins"
              :pending-adjustment-request="pendingAdjustmentRequest(ins)"
              :subreqs-missing="subreqsMissing(ins)"
              :limits-missing="limitsMissing(ins)"
              :expired-coverage="expiredCoverage(ins)"
              :unverified-policy="unverifiedPolicy(ins)"
              @adjustmentRequestSubmit="e => $emit('initialize')"
              @withdrawAdjustmentRequest="
                e => $emit('withdrawAdjustmentRequest', e)
              "
              @updateContract="e => $emit('initialize')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDateFromUTC } from '@aon/cfs-utils';
import VendorContractActions from './VendorContractActions';
import ContractRequest from './ContractRequest';
import { ACCEPTED, PENDING } from '@/lib/constants/contract-statuses';
import Status from '@aon/cfs-components/src/components/Status';
import LabelValueRow from '@aon/cfs-components/src/components/LabelValue/LabelValueRow';
import InsuranceAgreementTotal from './InsuranceAgreementTotal';

export default {
  name: 'VendorContract',
  components: {
    VendorContractActions,
    ContractRequest,
    Status,
    LabelValueRow,
    InsuranceAgreementTotal,
  },
  props: {
    inactive: {
      type: Boolean,
      default: false,
    },
    contract: {
      type: Object,
      required: true,
    },
    vendor: {
      type: Object,
      required: true,
    },
    notificationParams: {
      type: Object,
      default: null,
    },
    insuranceTotals: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showRequirements: false,
      acceptedStatus: ACCEPTED,
      pendingStatus: PENDING,
      filteredInsuranceTotals: [],
      leftList: [],
      rightList: [],
    };
  },
  computed: {
    statusColor() {
      return this.contract.status === 'TERMINATED' ||
        this.contract.status === 'DECLINED'
        ? 'aon-gray-04'
        : this.contract.status === 'PENDING'
        ? 'warning'
        : 'success';
    },
    status() {
      if (!this.isActive) {
        return this.$t(`vendors.contractStatus.inactive`);
      }

      return this.contract.status === PENDING || this.contract.pendingStatus
        ? this.$t(`vendors.contractStatus.pending`)
        : this.$t(`vendors.contractStatus.accepted`);
    },
    isActive() {
      const activateState =
        this.contract.status === ACCEPTED || this.contract.status === PENDING;
      const notExpired =
        this.contract.evergreen ||
        this.$moment.utc().diff(this.$moment.utc(this.contract.cease)) <= 0;

      return activateState && notExpired;
    },
    agreementStatus() {
      return this.filteredInsuranceTotals.some(i => !i.compliant)
        ? 'fa fa-exclamation compliance-status-icon non-compliant'
        : this.hasNotifications
        ? 'fa fa-exclamation compliance-status-icon notification'
        : 'fa fa-check compliance-status-icon compliant';
    },
    hasNotifications() {
      return this.filteredInsuranceTotals.some(
        ins =>
          this.pendingAdjustmentRequest(ins) ||
          this.subreqsMissing(ins) ||
          this.expiredCoverage(ins) ||
          this.limitsMissing(ins) ||
          this.unverifiedPolicy(ins)
      );
    },
    labelValues() {
      const { number, commence, cease, evergreen, vendorEmail } = this.contract;
      return [
        {
          label: this.$t('vendors.contractsHeaders.contractNumber'),
          value: number ? number : this.$t('vendors.notApplicable'),
        },
        {
          label: this.$t('vendors.contractsHeaders.startDate'),
          value: getDateFromUTC(commence),
        },
        {
          label: this.$t('vendors.contractsHeaders.endDate'),
          value: evergreen
            ? this.$t('vendors.contractsHeaders.evergreen')
            : cease
            ? getDateFromUTC(cease)
            : this.$t('vendors.notApplicable'),
        },
        {
          label: this.$t('vendors.contractsHeaders.primaryContact'),
          value: vendorEmail ?? this.$t('vendors.notApplicable'),
        },
      ];
    },
  },
  created() {
    this.filteredInsuranceTotals = this.insuranceTotals.filter(it =>
      it.contractIds.some(cid => cid === this.contract.id)
    );
    let space = 1;
    let left = true;
    this.filteredInsuranceTotals.forEach(ins => {
      left ? this.leftList.push(ins) : this.rightList.push(ins);

      let spaces = 1;
      spaces += this.pendingAdjustmentRequest(ins) ? 1 : 0;
      spaces += this.subreqsMissing(ins) ? 1 : 0;
      spaces += this.expiredCoverage(ins) ? 1 : 0;
      spaces += this.unverifiedPolicy(ins) ? 1 : 0;
      spaces += this.limitsMissing(ins) ? 1 : 0;

      let gap = space - spaces;
      if (gap <= 0) {
        space = gap * -1 + 1;
        left = !left;
      } else if (gap > 0) {
        space = gap;
      }
    });
  },
  methods: {
    pendingAdjustmentRequest(ins) {
      return !ins.compliant && ins.adjustmentRequestStatus === 'PENDING';
    },
    subreqsMissing(ins) {
      // Hide this notification when it is compliance
      return (
        !ins.compliant &&
        ins.similarPolicyIds.some(s => !ins.policyIds.includes(s))
      );
    },
    limitsMissing(ins) {
      return (
        !ins.compliant &&
        !!ins.policyIds.length &&
        !ins.unverifiedPolicyIds.length &&
        (ins.aggregateCoverage < ins.aggregateRequirement ||
          ins.perOccurrenceCoverage < ins.perOccurrenceRequirement ||
          ins.deductibleCoverage > ins.deductibleRequirement)
      );
    },
    expiredCoverage(ins) {
      const expired =
        ins.futureComplianceChanges?.some(
          f =>
            !f.compliance &&
            f.linkType === 'Coverage' &&
            f.change === 'Deactivate'
        ) ?? false;
      return !!ins.nonComplianceDate && ins.compliant && expired;
    },
    missingPolicy(ins) {
      return (
        !this.pendingAdjustmentRequest(ins) &&
        !ins.similarPolicyIds.length &&
        !ins.hasUnverifiedCoi
      );
    },
    unverifiedPolicy(ins) {
      return ins.hasUnverifiedCoi;
    },
  },
};
</script>

<style lang="scss">
.insurance-agreement {
  .v-sheet {
    .title {
      font-weight: 700;
      font-size: 1rem;
      color: $aon-peacock;
    }
  }
  .contract-title {
    font-weight: 700;
    color: $aon-gray-01;
    font-size: 1rem;
  }
  .v-card__text {
    padding: 1.5rem;
  }

  .two-column {
    overflow-x: auto;
    padding-top: 8px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;

    > div {
      flex: 1 1 auto;
    }
  }

  .compliance-status-icon {
    &.compliant {
      color: #1f9880;
    }

    &.notification {
      color: #ffa600;
    }

    &.non-compliant {
      color: #d13d3b;
    }
  }
}
</style>
