var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"60em"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('AppButton',_vm._g({attrs:{"loading":_vm.loading,"variation":"ghost","prepend-icon":"fal fa-external-link-square"}},on),[_vm._v(" "+_vm._s(_vm.$t('vendors.viewRequirements'))+" ")])]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-sheet',{staticClass:"px-10 py-10"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"h4 aon-peacock--text"},[_vm._v(" "+_vm._s(_vm.$t('vendors.requirementHeaders.title', [_vm.contract.name]))+" ")]),_c('AppButton',{attrs:{"variation":"icon"},on:{"click":function($event){_vm.open = false}}},[_c('v-icon',[_vm._v("fal fa-times")])],1)],1),_vm._l((_vm.contract.insuranceRequirements),function(req,i){return _c('v-card',{key:("req_" + i),staticClass:"my-4",attrs:{"outlined":"","flat":""}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('span',{staticClass:"h6 aon-gray-01--text"},[_vm._v(" "+_vm._s(_vm.$t(("mappings.insurance." + (req.insuranceType))))+" ")])])],1),(!_vm.companyIsMigrated && req.additionalInsured)?_c('v-row',[_c('v-col',[_c('span',{staticClass:"aon-gray-01--text"},[_vm._v(" "+_vm._s(_vm.$t("vendors.requirementHeaders.additionalInsured", [ req.additionalInsured ]))+" ")])])],1):_vm._e(),(!_vm.companyIsMigrated)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex"},_vm._l((_vm.labelValues(req)),function(col,j){return _c('LabelValue',{key:("col_" + j),staticClass:"pr-5",class:j === 2 && 'ml-auto',attrs:{"label":col.label,"value":col.value}})}),1)],1):_vm._e(),(!_vm.companyIsMigrated && _vm.coiClient(req.subrequirements).length)?_c('div',{staticClass:"subrequirements mt-4 pt-2"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('strong',[_vm._v(_vm._s(_vm.$t('vendors.COI_CLIENT.label')))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-wrap justify-space-between"},[_c('LabelValue',{staticClass:"pr-5",attrs:{"label":_vm.companyName,"value":_vm.coiClient(req.subrequirements)
                    .map(function (v) { return _vm.$t(("vendors.COI_CLIENT." + (v.name))); })
                    .join(', ')}})],1)],1)],1):_vm._e(),(
            !_vm.companyIsMigrated &&
              _vm.additionalCoverage(req.subrequirements).length
          )?_c('div',{staticClass:"subrequirements mt-4 pt-2"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('strong',[_vm._v(_vm._s(_vm.$t(("vendors.ADDITIONAL_COVERAGE.title." + (req.insuranceType)))))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-wrap justify-space-between"},[_c('span',{staticClass:"pr-5 label-value__value"},[_vm._v(_vm._s(_vm.additionalCoverage(req.subrequirements) .map(function (ac) { return ac.values.map(function (v) { return _vm.$t( ("vendors.ADDITIONAL_COVERAGE." + (_vm.getAdditionalCoverageText( ac, v ))) ); } ); }) .join(', ')))])])],1)],1):_vm._e(),(!_vm.companyIsMigrated && _vm.endorsement(req.subrequirements).length)?_c('div',{staticClass:"subrequirements mt-4 pt-2"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('strong',[_vm._v(_vm._s(_vm.$t('vendors.ENDORSEMENT.label')))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-wrap justify-space-between"},[_c('LabelValue',{staticClass:"pr-5",attrs:{"label":_vm.companyName,"value":_vm.endorsement(req.subrequirements)
                    .map(function (v) { return _vm.$t(("vendors.ENDORSEMENT." + (v.name))); })
                    .join(', ')}})],1)],1)],1):_vm._e(),(!_vm.companyIsMigrated && _vm.sublimit(req.subrequirements).length)?_c('div',{staticClass:"subrequirements mt-4 pt-2"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('strong',[_vm._v(_vm._s(_vm.$t('vendors.SUB_LIMIT.title')))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-wrap justify-space-between"},[_c('div',{staticClass:"pr-5 label-value__value"},[_c('SubLimitsList',{attrs:{"sub-limits":_vm.sublimit(req.subrequirements),"insurance-type":req.insuranceType}})],1)])],1)],1):_vm._e()],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }