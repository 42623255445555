<template>
  <v-dialog v-model="open" max-width="60em">
    <template v-slot:activator="{ on }">
      <AppButton
        :loading="loading"
        variation="ghost"
        prepend-icon="fal fa-external-link-square"
        v-on="on"
      >
        {{ $t('vendors.viewRequirements') }}
      </AppButton>
    </template>
    <v-sheet class="px-10 py-10">
      <div class="d-flex justify-space-between align-center">
        <span class="h4 aon-peacock--text">
          {{ $t('vendors.requirementHeaders.title', [contract.name]) }}
        </span>
        <AppButton variation="icon" @click="open = false">
          <v-icon>fal fa-times</v-icon>
        </AppButton>
      </div>
      <v-card
        v-for="(req, i) in contract.insuranceRequirements"
        :key="`req_${i}`"
        outlined
        flat
        class="my-4"
      >
        <v-card-text>
          <v-row dense>
            <v-col>
              <span class="h6 aon-gray-01--text">
                {{ $t(`mappings.insurance.${req.insuranceType}`) }}
              </span>
            </v-col>
          </v-row>
          <v-row v-if="!companyIsMigrated && req.additionalInsured">
            <v-col>
              <span class="aon-gray-01--text">
                {{
                  $t(`vendors.requirementHeaders.additionalInsured`, [
                    req.additionalInsured,
                  ])
                }}
              </span>
            </v-col>
          </v-row>
          <v-row v-if="!companyIsMigrated" dense>
            <v-col class="d-flex">
              <LabelValue
                v-for="(col, j) in labelValues(req)"
                :key="`col_${j}`"
                :label="col.label"
                :value="col.value"
                :class="j === 2 && 'ml-auto'"
                class="pr-5"
              />
            </v-col>
          </v-row>
          <div
            v-if="!companyIsMigrated && coiClient(req.subrequirements).length"
            class="subrequirements mt-4 pt-2"
          >
            <v-row dense>
              <v-col>
                <strong>{{ $t('vendors.COI_CLIENT.label') }}</strong>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="d-flex flex-wrap justify-space-between">
                <LabelValue
                  :label="companyName"
                  :value="
                    coiClient(req.subrequirements)
                      .map(v => $t(`vendors.COI_CLIENT.${v.name}`))
                      .join(', ')
                  "
                  class="pr-5"
                />
              </v-col>
            </v-row>
          </div>
          <div
            v-if="
              !companyIsMigrated &&
                additionalCoverage(req.subrequirements).length
            "
            class="subrequirements mt-4 pt-2"
          >
            <v-row dense>
              <v-col>
                <strong>{{
                  $t(`vendors.ADDITIONAL_COVERAGE.title.${req.insuranceType}`)
                }}</strong>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="d-flex flex-wrap justify-space-between">
                <span class="pr-5 label-value__value">{{
                  additionalCoverage(req.subrequirements)
                    .map(ac => {
                      return ac.values.map(v =>
                        $t(
                          `vendors.ADDITIONAL_COVERAGE.${getAdditionalCoverageText(
                            ac,
                            v
                          )}`
                        )
                      );
                    })

                    .join(', ')
                }}</span>
              </v-col>
            </v-row>
          </div>
          <div
            v-if="!companyIsMigrated && endorsement(req.subrequirements).length"
            class="subrequirements mt-4 pt-2"
          >
            <v-row dense>
              <v-col>
                <strong>{{ $t('vendors.ENDORSEMENT.label') }}</strong>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="d-flex flex-wrap justify-space-between">
                <LabelValue
                  :label="companyName"
                  :value="
                    endorsement(req.subrequirements)
                      .map(v => $t(`vendors.ENDORSEMENT.${v.name}`))
                      .join(', ')
                  "
                  class="pr-5"
                />
              </v-col>
            </v-row>
          </div>
          <div
            v-if="!companyIsMigrated && sublimit(req.subrequirements).length"
            class="subrequirements mt-4 pt-2"
          >
            <v-row dense>
              <v-col>
                <strong>{{ $t('vendors.SUB_LIMIT.title') }}</strong>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="d-flex flex-wrap justify-space-between">
                <div class="pr-5 label-value__value">
                  <SubLimitsList
                    :sub-limits="sublimit(req.subrequirements)"
                    :insurance-type="req.insuranceType"
                  />
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-sheet>
  </v-dialog>
</template>

<script>
import LabelValue from '@aon/cfs-components/src/components/LabelValue/LabelValue';
import {
  coiClient,
  formatRequirement,
  endorsement,
  additionalCoverage,
  sublimit,
} from '@/lib/helpers';
import SubLimitsList from '@/components/SubLimitsList';
import { mapState } from 'vuex';

export default {
  name: 'ContractRequirementDialog',
  components: {
    LabelValue,
    SubLimitsList,
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      open: false,
      loading: false,
    };
  },
  computed: {
    ...mapState('companies', {
      companyName: state => state.companyProfile.legalName,
      companyIsMigrated: state => state?.company?.migrated,
    }),
  },
  methods: {
    coiClient,
    endorsement,
    additionalCoverage,
    formatRequirement,
    sublimit,
    labelValues(req) {
      const {
        policyType,
        territory,
        insuranceType,
        coverage,
        perOccurrenceCoverage,
        deductible,
      } = req;

      const baseLabels = [
        {
          label: this.$t('vendors.requirementHeaders.policyType'),
          value: this.$t(`vendors.policyTypes.${policyType}`),
        },
        {
          label: this.$t('vendors.requirementHeaders.territory'),
          value: territory,
        },
      ];

      if (coverage) {
        baseLabels.push({
          label: this.$t('vendors.requirementHeaders.aggregateCoverage'),
          value: this.formatRequirement(insuranceType, coverage),
        });
      }

      if (insuranceType === 'WRK_COMP') {
        if (perOccurrenceCoverage) {
          baseLabels.push({
            label: this.$t('vendors.requirementHeaders.perOccurrenceCoverage'),
            value: this.formatRequirement(insuranceType, perOccurrenceCoverage),
          });
        }

        if (deductible) {
          baseLabels.push({
            label: this.$t('vendors.requirementHeaders.deductible'),
            value: this.formatRequirement(insuranceType, deductible),
          });
        }
      }

      if (insuranceType === 'LIQUOR_LIAB') {
        if (perOccurrenceCoverage) {
          baseLabels.push({
            label: this.$t('vendors.requirementHeaders.eachCommonCause'),
            value: this.formatRequirement(insuranceType, perOccurrenceCoverage),
          });
        }
      }

      if (insuranceType === 'EO_PROF_LIAB') {
        if (perOccurrenceCoverage) {
          baseLabels.push({
            label: this.$t('vendors.requirementHeaders.eachClaim'),
            value: this.formatRequirement(insuranceType, perOccurrenceCoverage),
          });
        }
      }

      if (insuranceType === 'BUSINESS') {
        if (perOccurrenceCoverage) {
          baseLabels.push({
            label: this.$t('vendors.requirementHeaders.periodOfIndemnity'),
            value: this.formatRequirement(insuranceType, perOccurrenceCoverage),
          });
        }
      }

      if (insuranceType === 'COM_AUTO') {
        if (perOccurrenceCoverage) {
          baseLabels.push({
            label: this.$t('vendors.requirementHeaders.combinedSingleLimit'),
            value: this.formatRequirement(insuranceType, perOccurrenceCoverage),
          });
        }
      }

      if (
        insuranceType !== 'WRK_COMP' &&
        insuranceType !== 'LIQUOR_LIAB' &&
        insuranceType !== 'EO_PROF_LIAB' &&
        insuranceType !== 'BUSINESS' &&
        insuranceType !== 'COM_AUTO'
      ) {
        baseLabels.push(
          {
            label: this.$t('vendors.requirementHeaders.perOccurrenceCoverage'),
            value: this.formatRequirement(insuranceType, perOccurrenceCoverage),
          },
          {
            label: this.$t('vendors.requirementHeaders.deductible'),
            value: this.formatRequirement(insuranceType, deductible),
          }
        );
      }

      return baseLabels;
    },
    getAdditionalCoverageText(additionalCoverage, value) {
      return `${additionalCoverage.name}${
        additionalCoverage.name === 'AUTO_LIAB' ? '.' : ''
      }${value.value ?? ''}`;
    },
  },
};
</script>

<style lang="scss">
.subrequirements {
  border-top: 1px solid rgba(0, 0, 0, 0.17);
}
</style>
